<script>
import { addMonths, parse, isEqual, addDays } from 'date-fns'
import formatDate from '@/utils/format-date'

import FormValidationRules from '@/mixins/formValidationRules.vue'

const DATE_FORMAT = 'YYYY-MM-DD HH:mm'

export const IN_MODE = 'expires_in'
export const AT_MODE = 'expires_at'
export const END_MODE = 'expires_end'
export const LAST_ACTIVITY_MODE = 'expires_last_activity'
export const FIRST_ACTIVITY_MODE = 'expires_first_activity'

export const EXPIRATION_MODES = [
    IN_MODE,
    AT_MODE,
    END_MODE,
    LAST_ACTIVITY_MODE,
    FIRST_ACTIVITY_MODE,
]

export default {
    mixins: [FormValidationRules],
    name: 'KExpirationPicker',
    props: {
        target: { type: Object, required: true },
        label: { type: String },
        noneOptionLabel: { type: String, default: 'Without expiration' },
        disabled: Boolean,
        vertical: Boolean,

        withAtMode: { type: [Boolean, String] },
        withInMode: { type: [Boolean, String] },
        withEndMode: { type: [Boolean, String] },
        withLastActivityMode: { type: [Boolean, String] },
        withFirstActivityMode: { type: [Boolean, String] },
        withExpireReminderMode: Boolean,

        atModeRules: { type: Array, default: () => [] },
        inModeRules: { type: Array, default: () => [] },
        endModeRules: { type: Array, default: () => [] },
        lastActivityModeRules: { type: Array, default: () => [] },
        firstActivityModeRules: { type: Array, default: () => [] },

        suffix: { type: String },
        outlined: { type: Boolean },
    },
    data() {
        return {
            parameter: undefined,
        }
    },
    created() {
        this.setParameter(this.target[this.target_key])
    },
    watch: {
        target: {
            handler: function (value) {
                if (!isEqual(this.parameter, value[this.target_key])) {
                    this.setParameter(value[this.target_key])
                }
            },
            deep: true,
        },
        parameter(value, old) {
            const hasError = this.$refs[this.selected_mode]?.hasError
            if (value !== old && !hasError) {
                this.setFor(this.selected_mode, value)
            }
        },
        selected_mode(value) {
            if (!value) this.$set(this.target, 'with_expire_reminder', false)
        },
    },
    computed: {
        fieldsRules() {
            return {
                [AT_MODE]: [this.rules.required, this.rules.futureDate, ...this.atModeRules],
                [IN_MODE]: [
                    this.rules.required,
                    this.rules.positive,
                    ...(this.inModeRules.length
                        ? this.inModeRules
                        : [this.rules.periodDurationYears(2)]),
                ],
                [END_MODE]: [
                    this.rules.required,
                    this.rules.positive,
                    ...(this.endModeRules.length
                        ? this.endModeRules
                        : [this.rules.periodDurationMonths(60)]),
                ],
                [LAST_ACTIVITY_MODE]: [
                    this.rules.required,
                    this.rules.positive,
                    ...(this.lastActivityModeRules.length
                        ? this.lastActivityModeRules
                        : [this.rules.periodDurationYears(2)]),
                ],
                [FIRST_ACTIVITY_MODE]: [
                    this.rules.required,
                    this.rules.positive,
                    ...(this.firstActivityModeRules.length
                        ? this.firstActivityModeRules
                        : [this.rules.periodDurationYears(2)]),
                ],
            }
        },
        fieldsMapping() {
            return {
                [IN_MODE]: typeof this.withInMode === 'string' ? this.withInMode : IN_MODE,
                [AT_MODE]: typeof this.withAtMode === 'string' ? this.withAtMode : AT_MODE,
                [END_MODE]: typeof this.withEndMode === 'string' ? this.withEndMode : END_MODE,
                [LAST_ACTIVITY_MODE]:
                    typeof this.withLastActivityMode === 'string'
                        ? this.withLastActivityMode
                        : LAST_ACTIVITY_MODE,
                [FIRST_ACTIVITY_MODE]:
                    typeof this.withFirstActivityMode === 'string'
                        ? this.withFirstActivityMode
                        : FIRST_ACTIVITY_MODE,
            }
        },
        options() {
            const byEnabled = ({ value }) => this.modes.includes(value) || value === null
            return [
                { value: null, text: this.$t(this.noneOptionLabel) },
                { value: AT_MODE, text: this.$c(this.$t('fixed date')) },
                { value: IN_MODE, text: this.$c(this.$t('fixed period')) },
                { value: END_MODE, text: this.$c(this.$t('end of period')) },
                { value: LAST_ACTIVITY_MODE, text: this.$c(this.$t('from last activity')) },
                { value: FIRST_ACTIVITY_MODE, text: this.$c(this.$t('from first activity')) },
            ].filter(byEnabled)
        },
        has_expires_in() {
            return this.isModeEnabled(IN_MODE) && this.hasProp(IN_MODE)
        },
        has_expires_end() {
            return this.isModeEnabled(END_MODE) && this.hasProp(END_MODE)
        },
        has_expires_at() {
            return this.isModeEnabled(AT_MODE) && this.hasProp(AT_MODE)
        },
        has_expires_last_activity() {
            return this.isModeEnabled(LAST_ACTIVITY_MODE) && this.hasProp(LAST_ACTIVITY_MODE)
        },
        has_expires_first_activity() {
            return this.isModeEnabled(FIRST_ACTIVITY_MODE) && this.hasProp(FIRST_ACTIVITY_MODE)
        },
        selected_mode: {
            get() {
                if (this.has_expires_in) {
                    return IN_MODE
                } else if (this.has_expires_end) {
                    return END_MODE
                } else if (this.has_expires_at) {
                    return AT_MODE
                } else if (this.has_expires_last_activity) {
                    return LAST_ACTIVITY_MODE
                } else if (this.has_expires_first_activity) {
                    return FIRST_ACTIVITY_MODE
                }
                return null
            },
            set(mode) {
                this.setFor(mode)
            },
        },
        target_key() {
            return this.fieldsMapping[this.selected_mode]
        },
        modes() {
            const modes = []

            if (this.withAtMode) modes.push(AT_MODE)
            if (this.withInMode) modes.push(IN_MODE)
            if (this.withEndMode) modes.push(END_MODE)
            if (this.withLastActivityMode) modes.push(LAST_ACTIVITY_MODE)
            if (this.withFirstActivityMode) modes.push(FIRST_ACTIVITY_MODE)

            return modes
        },
        min_date() {
            return addDays(new Date(), 1)
        },
        expiration_type_label() {
            if (!this.selected_mode) return
            return this.options.find(option => option.value === this.selected_mode)?.text
        },
        expiration_type_value() {
            if (!this.target_key) return
            return this.target[this.target_key]
        },
        plural_suffix() {
            return this.suffix === 'min' ? 'minutes|minute|minutes' : 'days|day|days'
        },
    },
    methods: {
        hasProp(mode) {
            return !!this.target[this.fieldsMapping[mode]]
        },
        isModeEnabled(mode) {
            return this.modes.includes(mode)
        },
        setTime(time) {
            if (!time) return
            const date = parse(this.parameter)
            const parts = time.split(':')
            date.setHours(parts[0])
            date.setMinutes(parts[1])
            this.parameter = date
        },
        setDate(value) {
            if (!value) return
            const parsed = parse(value)
            const date = parse(this.parameter)
            date.setFullYear(parsed.getFullYear())
            date.setMonth(parsed.getMonth())
            date.setDate(parsed.getDate())
            this.parameter = date
        },
        setFor(mode, override = null) {
            const value = override || this.target[this.fieldsMapping[mode]]
            const expiryPool = this.modes.reduce((expiration, key) => {
                const field = this.fieldsMapping[key]

                expiration[field] = null

                if (mode === key) {
                    switch (mode) {
                        case IN_MODE:
                        case END_MODE:
                        case LAST_ACTIVITY_MODE:
                        case FIRST_ACTIVITY_MODE: {
                            expiration[field] = value || 1
                            break
                        }
                        case AT_MODE: {
                            expiration[field] = formatDate(
                                value || addMonths(new Date(), 1),
                                DATE_FORMAT
                            )
                            break
                        }
                        default: {
                            expiration[field] = null
                        }
                    }
                }

                return expiration
            }, {})

            Object.keys(expiryPool).forEach(key => {
                this.$set(this.target, key, expiryPool[key])
            })
        },
        setParameter(value) {
            this.parameter = this.has_expires_at ? new Date(value) : value
        },
    },
}
</script>
<template>
    <v-row dense>
        <v-col sm="12" :md="vertical || !selected_mode ? 12 : 6">
            <v-select
                v-model="selected_mode"
                :items="options"
                :label="$t(label || 'expiration')"
                :outlined="outlined"
                :hide-details="!selected_mode"
                :disabled="disabled"
                dense
                class="first-capitalize" />
        </v-col>
        <template v-if="has_expires_at">
            <v-col :cols="vertical ? 6 : 3">
                <k-date-field
                    :value="$d(parameter, 'YYYY-MM-DD')"
                    :label="$t('date')"
                    :disabled="disabled"
                    :min-date="min_date"
                    :rules="fieldsRules.expires_at"
                    :outlined="outlined"
                    dense
                    width="100"
                    @input="setDate" />
            </v-col>
            <v-col :cols="vertical ? 6 : 3">
                <k-date-field
                    :value="$d(parameter, 'HH:mm')"
                    :disabled="disabled"
                    :label="$t('time')"
                    :rules="[rules.required]"
                    :outlined="outlined"
                    dense
                    width="100"
                    mode="time"
                    @input="setTime" />
            </v-col>
        </template>
        <v-col v-else cols="12" :md="vertical ? 12 : 6">
            <k-text-field
                v-if="has_expires_in"
                v-model.number="parameter"
                :disabled="disabled"
                :rules="fieldsRules.expires_in"
                :outlined="outlined"
                dense
                placeholder="50"
                ref="expires_in"
                type="number"
                :suffix="$t('days|day|days', { count: target[fieldsMapping.expires_in] || 1 })" />
            <k-text-field
                v-else-if="has_expires_last_activity"
                v-model.number="parameter"
                :disabled="disabled"
                :rules="fieldsRules.expires_last_activity"
                :outlined="outlined"
                dense
                placeholder="50"
                ref="expires_last_activity"
                type="number"
                :suffix="
                    $t(plural_suffix, { count: target[fieldsMapping.expires_last_activity] || 1 })
                " />
            <k-text-field
                v-else-if="has_expires_first_activity"
                v-model.number="parameter"
                :disabled="disabled"
                :rules="fieldsRules.expires_first_activity"
                :outlined="outlined"
                dense
                placeholder="1"
                ref="expires_first_activity"
                type="number"
                :suffix="
                    $t(plural_suffix, { count: target[fieldsMapping.expires_first_activity] || 1 })
                " />
            <k-text-field
                v-else-if="has_expires_end"
                v-model.number="parameter"
                :disabled="disabled"
                :rules="fieldsRules.expires_end"
                :outlined="outlined"
                dense
                placeholder="1"
                ref="expires_end"
                type="number"
                :suffix="
                    $t('months|month|months', { count: target[fieldsMapping.expires_end] || 1 })
                " />
        </v-col>
        <v-col v-if="selected_mode && withExpireReminderMode" cols="12">
            <v-switch
                v-model="target.with_expire_reminder"
                :label="$t('enable notification for pass expiration')"
                :inset="outlined"
                class="mt-0"
                hide-details />
        </v-col>
    </v-row>
</template>
